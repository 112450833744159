<template>
  <b-sidebar
    id="transaction-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Transaction #{{ transactionData.id }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="
            resetForm();
            hide();
          "
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="transactionForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validateForm()"
        >
          <div class="d-flex justify-content-between align-items-center mb-1">
            <b-badge
              :variant="`light-${
                resolveTransactionTypeVariantAndIcon(
                  transactionData.transactionTypeId
                ).variant
              }`"
            >
              <feather-icon
                :icon="
                  resolveTransactionTypeVariantAndIcon(
                    transactionData.transactionTypeId
                  ).icon
                "
                class="mr-25"
              />
              <span>{{ transactionData.transactionType.name }}</span>
            </b-badge>

            <b-badge
              :variant="`light-${resolveTransactionStatusVariantAndIcon(
                transactionData.transactionStatus
              )}`"
            >
              {{ transactionData.transactionStatus.name }}
            </b-badge>
          </div>

          <b-form-group
            v-if="transactionData.account"
            label="Account Name"
            label-for="account-name"
          >
            <b-input-group class="disabled">
              <b-input-group-prepend is-text>
                <feather-icon
                  size="16"
                  icon="BriefcaseIcon"
                />
              </b-input-group-prepend>
              <b-form-input
                id="account-name"
                v-model="transactionData.account.name"
                name="account-name"
                readonly
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            v-if="transactionData.bookingId"
            label="Booking Voucher"
            label-for="booking-voucher"
          >
            <b-input-group class="disabled">
              <b-input-group-prepend is-text>
                <feather-icon
                  size="16"
                  icon="FileTextIcon"
                />
              </b-input-group-prepend>
              <b-form-input
                id="booking-voucher"
                v-model="transactionData.booking.voucherNum"
                name="booking-voucher"
                readonly
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            v-if="transactionData.postId"
            label="Post Ref"
            label-for="post-ref"
          >
            <b-input-group class="disabled">
              <b-input-group-prepend is-text>
                <feather-icon
                  size="16"
                  icon="FileTextIcon"
                />
              </b-input-group-prepend>
              <b-form-input
                id="post-ref"
                v-model="transactionData.postId"
                name="post-ref"
                readonly
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            v-if="transactionData.tourId"
            label="Tour Voucher"
            label-for="tour-voucher"
          >
            <b-input-group class="disabled">
              <b-input-group-prepend is-text>
                <feather-icon
                  size="16"
                  icon="FileTextIcon"
                />
              </b-input-group-prepend>
              <b-form-input
                id="tour-voucher"
                v-model="transactionData.tour.voucherNum"
                name="tour-voucher"
                readonly
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Date"
            label-for="date"
          >
            <b-input-group class="disabled">
              <b-input-group-prepend is-text>
                <feather-icon
                  size="16"
                  icon="CalendarIcon"
                />
              </b-input-group-prepend>
              <b-form-input
                id="date"
                v-model="dateFormated"
                name="date"
                readonly
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Amount"
            label-for="amount"
          >
            <b-input-group
              prepend="£"
              class="disabled"
            >
              <b-form-input
                id="amount"
                v-model="transactionData.amount"
                name="amount"
                readonly
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="New Balance"
            label-for="new-balance"
          >
            <b-input-group
              prepend="£"
              class="disabled"
            >
              <b-form-input
                id="new-balance"
                v-model="transactionData.newBalance"
                name="new-balance"
                readonly
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="transactionData.description"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex d-flex-row justify-content-between mt-2">
            <b-button
              type="submit"
              variant="primary"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              Edit
            </b-button>

            <b-button
              v-if="
                !transactionData.transactionStatus.isCancelled &&
                transactionData.bookingId === null &&
                transactionData.postId === null
              "
              @click="cancel"
              variant="danger"
            >
              Cancelled
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BSidebar,
  BSpinner,
} from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAccountTransaction from '@/modules/account/composables/useAccountTransaction';
import useUtils from '@/composables/useUtils';
import store from '@/store';
import Swal from 'sweetalert2';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    transaction: {
      type: Object,
      require: true,
    },
  },
  setup(props, ctx) {
    const toast = useToast();
    const { formatDateDdMmYyyyHi, convertUtcToLocalDate } = useUtils();
    const {
      cancelTransaction,
      resolveTransactionStatusVariantAndIcon,
      resolveTransactionTypeVariantAndIcon,
    } = useAccountTransaction();

    const transactionForm = ref(null);
    const transactionData = ref(props.transaction);
    const isLoading = ref(false);
    const dateFormated = computed(() =>
      formatDateDdMmYyyyHi(
        convertUtcToLocalDate(transactionData.value.transactionDateUtc)
      )
    );

    const resetForm = () => {
      transactionForm.value.reset();
    };

    const validateForm = async () => {
      if (isLoading.value) return;

      isLoading.value = true;

      const valid = await transactionForm.value.validate();
      if (!valid) {
        isLoading.value = false;
        return;
      }

      try {
        await store.dispatch(
          'accountTransaction/updateTransaction',
          transactionData.value
        );
        toast.success({
          component: ToastificationContent,
          props: {
            title: 'Transaction has been edited successfully!',
            icon: 'CheckIcon',
          },
        });
        ctx.emit('form-submitted');
      } catch (error) {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'An error occurred.',
            icon: 'XCircleIcon',
          },
        });
      }

      isLoading.value = false;
    };

    const cancel = async () => {
      Swal.fire({
        title: 'Cancel transactions',
        icon: 'warning',
        footer:
          '<span class="text-primary">The description must be at least 10 characters.</span>',
        input: 'textarea',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
          placeholder: 'Description',
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel it!',
        showLoaderOnConfirm: true,
        async preConfirm(login) {
          if (!login || login.length < 10) return null;
          return await cancelTransaction(transactionData.value.id, login);
        },
      }).then((result) => {
        if (result.value) ctx.emit('form-submitted');
      });
    };

    return {
      // Reactive
      transactionForm,
      transactionData,
      dateFormated,
      isLoading,

      // Method
      cancel,
      resetForm,
      resolveTransactionStatusVariantAndIcon,
      resolveTransactionTypeVariantAndIcon,
      validateForm,

      // Validator
      required,
    };
  },
  components: {
    BBadge,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BSidebar,
    ValidationObserver,
    ValidationProvider,
    BSpinner,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#transaction-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
